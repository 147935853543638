import React, { FC } from 'react';
import BodySession, { TitleBody } from '../../components/BodySession';

export interface NiveisERegalisTextProps { }
const listOfLvels = [
    {
        title: 'Nível 1: Afiliados Iniciais',
        text: [
            'Regalias básicas como brindes e materiais promocionais.',
            'Acesso ao painel exclusivo de acompanhamento de indicações.',
            'Salas de sinais para compartilhamentos.'
        ]
    },
    {
        title: 'Nível 2: Afiliados Intermediários',
        text: [
            'Todas as regalias do Nível 1.',
            'Chamadas exclusivas com embaixadores do cassino.',
            'Brindes de alto valor, como gadgets tecnológicos.',
            'Acesso a eventos VIP do cassino.'
        ]
    },
    {
        title: 'Nível 3: Afiliados Premium',
        text: [
            'Todas as regalias do Nível 2.',
            'Acesso a eventos internacionais organizados pelo cassino.',
            'Presentes de luxo, como relógios, joias e viagens exclusivas.'
        ]
    }
]

const NiveisERegalisText: FC<NiveisERegalisTextProps> = () => {
    return (
        <BodySession style={{ textAlign: 'justify' }}>
            <TitleBody>Níveis e Regalias</TitleBody>
            <p style={{ maxWidth: 800 }}>Os afiliados serão classificados em níveis, conforme o número de indicações e o desempenho, e receberão regalias especiais:</p>
            <div style={{ maxWidth: 800, width: '100%' }}>

                {listOfLvels.map(({ title, text }, index) => (
                    <div key={`regalia-${index}`}>
                        <h5 style={{
                            lineHeight: '1',
                            marginBottom: '0.5rem',
                            textAlign: 'start'
                        }}>{title}</h5>
                        {
                            text.map((item, indexText) => (
                                <div key={`regalias-${index}-text-${indexText}`}>
                                    <span >{item}</span><br />
                                </div>
                            ))
                        }
                    </div>
                ))}
            </div>
        </BodySession>
    );
};

export default NiveisERegalisText;