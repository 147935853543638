import React from "react";
import Forms from "./forms";
import { DivCollumn, Session, TableIcon, TableItem, TableList, Title, Parag } from "./styles";

import { ReactComponent as EmailIcon } from "../../assets/Email.svg";
import { ReactComponent as BuildingIcon } from "../../assets/Building.svg";
// import { ReactComponent as PhoneIcon } from "../../assets/Phone.svg";
import SociaisMedeas from "./SociaisMediasCards";
import { useMediaQuery } from "@uidotdev/usehooks";

const TabList = [
    {
        icon: <EmailIcon />,
        text: "grupoyallo@gmail.com",
    },
    {
        icon: <BuildingIcon />,
        text: "São Paulo, SP  Brasil",
    },
    // {
    //     icon: <PhoneIcon />,
    //     text: "+55 11 95487-4958",
    // },
];

const ContatoPage: React.FC = () => {
    const inCollumn = useMediaQuery("(max-width: 1106px)");
    return (
        <Session>
            <DivCollumn>
                <Title>Vamos trabalhar juntos!</Title>
                <Parag>
                    Receba oportunidades exclusivas de parceria! Ao se cadastrar, você estará elegível para receber propostas de colaboração e aproveitar os
                    benefícios de nossa rede de parceiros.
                </Parag>
                {TabList.map((item, index) => {
                    return (
                        <TableList key={`nav-bar-${index}`}>
                            <TableItem>
                                <TableIcon>{React.isValidElement(item.icon) && React.cloneElement(item.icon)}</TableIcon>
                                <p>{item.text}</p>
                            </TableItem>
                        </TableList>
                    );
                })}
                {!inCollumn && <SociaisMedeas />}
            </DivCollumn>
            <DivCollumn>
                <Forms />
                {inCollumn && <SociaisMedeas />}
            </DivCollumn>
        </Session>
    );
};

export default ContatoPage;
