import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";
import Input, { InputTypes } from "../../components/Input";
import Buttom from "../../components/Button";

const SocialLinksSession = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SocialLinksBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: 11px;
`;

export interface SocialLinksProps {
    links: string[];
    onChange?: (links: string[]) => void;
}

const SocialLinks: FC<SocialLinksProps> = ({ links, onChange }) => {
    const [socialLinks, setSocialLinks] = useState<string[]>(links);

    const updateLinks = useCallback(
        (value: string, index: number) => {
            setSocialLinks((old) => {
                const newLinks = [...old];
                newLinks[index] = value;
                onChange?.(newLinks);
                return newLinks;
            });
        },
        [onChange]
    );

    const addLink = useCallback(() => {
        setSocialLinks((old) => {
            const newLinks = [...old, ""].splice(0, 6);
            onChange?.(newLinks);
            return newLinks;
        });
    }, [onChange]);

    const removeLink = useCallback(
        (index: number) => {
            setSocialLinks((old) => {
                const newLinks = old.filter((_, i) => i !== index);
                onChange?.(newLinks);
                return newLinks;
            });
        },
        [onChange]
    );

    return (
        <SocialLinksSession>
            {socialLinks.map((link, index) => {
                return (
                    <SocialLinksBody key={`SocialLinksBody-${index}`}>
                        <Input
                            key={`social-link-${index}`}
                            inputType={InputTypes.NORMAL}
                            placeholder="Link"
                            type="text"
                            alt="Link"
                            onChange={(e) => {
                                if (e?.target) {
                                    updateLinks(e.target.value, index);
                                }
                            }}
                            value={link}
                            style={{ flex: 1 }}
                        />
                        <Buttom
                            onClick={() => {
                                if (index === 0) {
                                    addLink();
                                } else {
                                    removeLink(index);
                                }
                            }}
                        >
                            <p style={{ fontSize: "30px" }}>{index === 0 ? "+" : "-"}</p>
                        </Buttom>
                    </SocialLinksBody>
                );
            })}
        </SocialLinksSession>
    );
};

export default SocialLinks;
