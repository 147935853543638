import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import styled, { useTheme } from 'styled-components';
import BodySession, { TitleBody } from '../../components/BodySession';

import { ReactComponent as PinIcon } from '../../assets/pin.svg';


const redGradient = 'linear-gradient(0deg, #ff2d3c 48%, #ff5454 68%, #ff2d35 89%)'
const blueGradient = 'linear-gradient(0deg, #5955d8 48%, #423ce9 68%, #5955d8 89%)'
const orangeGradient = 'linear-gradient(0deg, #fefe27 48%, #fbde8e 68%, #fbfb28 89%)'

const RoletaContainer = styled.div<{ scale?: number }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    * {
        -webkit-user-select: none; /* Para navegadores baseados em WebKit */
        -moz-user-select: none; /* Para Firefox */
        -ms-user-select: none; /* Para Internet Explorer */
        user-select: none; /* Para todos os navegadores */
    }
    touch-action: none;
    transform: scale(${(props) => props.scale || 1});
    overflow: visible;

    &::before {
        content: '';
        position:absolute;
        width: 1100px;
        height: 1100px;
        border-radius: 50%;
        background: ${(props) => orangeGradient};

    }
`;

const RoletaStyled = styled(animated.div)`
    position: relative;
    min-width: 1000px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 5px solid #000;
    overflow: hidden;
    user-select: none; /* Desabilitar seleção de texto */
    touch-action: none;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
`;

const RoletaItem = styled.div<{ bgColor: boolean }>`
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 50%;
    transform-origin: 0 100%;
    background: ${(props) => props.bgColor ? blueGradient : redGradient};
    animation: shine 1s infinite linear;
    clip-path: polygon(0 0, 60% 0, 0 100%);
    user-select: none !important; 

    
    div {
        position: absolute;
        /* transform: translate(-15%, 100%); */
        top: 130px;
        left: -45px;
        width: 270px;
        height: 100px;
        transform: rotate(-75deg);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span {
        user-select: none !important; 
        word-break: break-word;
        word-wrap: break-word;
        text-align: center;
        font-weight: bold;
    }

    @keyframes shine {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`;

const IndcatorText = styled.svg`
    position: absolute;
    transform: translate(-107%, 10%) rotate(16deg);
    /* background-color: green; */

    z-index: 1;
    width: 230px;
    height: 80px;
    font-size: 24px;
    font-weight: bold;
    * {
        color: #fff;
    }
`;

const PinIconST = styled(animated.div)`
    position: absolute;
    z-index: 10;
    width: 90px;
    height: 90px;
    top: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
    > svg {
        fill: ${(props) => props.theme.pallets.second.orange};
        stroke: #000;
        stroke-width: 10px;
    }
`;

const CenterCircle = styled.div<{ size?: string, color?: string }>`
    position: absolute;
    width: ${(props) => props.size || '100px'};
    height: ${(props) => props.size || '100px'};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: ${(props) => props.color || props.theme.pallets.second.orange};
`;

const items = [
    ['Kit SSSGRUPO\n(camiseta, boné e pulseira)', '0-1000'],
    ['Acessório SSSGRUPO (Medalha SSS, garrafa)', '1000-5000'],
    ['Fones sem fio', '5000-10000'],
    ['Tablet', '10000-30000'],
    ['Smartphone', '30000-50000'],
    ['Viagem 100% paga', '50000-100000'],
    ['Moto', '100000-300000'],
    ['KIT APPLE', '3000000-500000'],
    ['Carro Popular', '500000-1M'],
    ['KIT GRIFE', '1M-3M'],
    ['BMW', '3M-5M'],
    ['Lamborghini', '5M-10M'],
];

const RoletaInterativa = () => {
    const [scale, setScale] = useState(1);
    const [selected, setSelected] = useState(0);
    const auxSelected = useRef(0);
    const [{ rotateZ }, api] = useSpring(() => ({
        rotateZ: -15,
        onChange: ({ value }) => {
            const select = Math.ceil((Math.abs(value.rotateZ) % 360) / (360 / items.length));
            if (auxSelected.current !== select) {
                auxSelected.current = select;
                setSelected(select - 1);
            }
        }
    }));
    const [{ rotate: pinRotate }, pinApi] = useSpring(() => ({ rotate: 0 }));
    const wheelRef = useRef<HTMLDivElement>(null);
    const accumulatedRotationRef = useRef(0);
    const previousAngleRef = useRef(0);
    const previousTimeRef = useRef(0);
    const maxPixels = 800; // Tamanho máximo da roleta em pixels

    const ajustarEscala = () => {
        const larguraTela = window.innerWidth;
        const alturaTela = window.innerHeight;

        // Calcula o valor da escala para ocupar 80% da tela, mas limitado a 1000px
        const larguraDesejada = Math.min(larguraTela * 0.8, maxPixels);
        const alturaDesejada = Math.min(alturaTela * 0.8, maxPixels);

        // Define a escala com base nos valores calculados
        const scale = Math.min(larguraDesejada / 1000, alturaDesejada / 1000);

        return scale;
    };

    useEffect(() => {
        const handleResize = () => {
            setScale(ajustarEscala());
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const bind = useDrag(
        ({ xy: [clientX, clientY], first, last, down, timeStamp, event }) => {
            event.preventDefault();

            const rect = wheelRef.current.getBoundingClientRect();
            const wheelCenterX = rect.left + rect.width / 2;
            const wheelCenterY = rect.top + rect.height / 2;

            const currentAngle =
                Math.atan2(clientY - wheelCenterY, clientX - wheelCenterX) * (180 / Math.PI);

            if (first) {
                api.stop();
                const currentRotation = rotateZ.get();
                accumulatedRotationRef.current = currentRotation;
                previousAngleRef.current = currentAngle;
                previousTimeRef.current = timeStamp;
            }

            let deltaAngle = currentAngle - previousAngleRef.current;

            if (deltaAngle > 180) deltaAngle -= 360;
            if (deltaAngle < -180) deltaAngle += 360;

            accumulatedRotationRef.current += deltaAngle;
            previousAngleRef.current = currentAngle;

            const deltaTime = timeStamp - previousTimeRef.current;
            previousTimeRef.current = timeStamp;
            const angularVelocity = deltaTime > 0 ? (deltaAngle / deltaTime) * 1000 : 0;

            api.start({
                rotateZ: accumulatedRotationRef.current + angularVelocity,
                config: {
                    tension: 10,
                    mass: 0.5,
                    friction: 10,
                },
            });
            // Calcula a oscilação do pino com base na velocidade angular
            pinApi.start({
                rotate: Math.max(-50, Math.min(-(angularVelocity / 10), 50)), // Ajuste para aumentar ou reduzir a amplitude de oscilação
                config: { tension: 120, friction: 5 },
            });
        },
        { eventOptions: { passive: false } }
    );



    const transform = rotateZ.to((rz) => `rotate(${rz}deg)`);
    const transformPin = pinRotate.to((r) => `translate(-50%, -50%) rotate(${r}deg)`);

    return (
        <BodySession>
            <TitleBody>Tabela de Indicações e Premiações</TitleBody>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px',
            }}>
                <div>
                    <h4>Meta de indicações: {items[selected][1]}</h4>
                    <span>Prêmio: {items[selected][0]}</span>
                </div>
            </div>
            <div style={{
                height: `${Math.floor(1100 * scale)}px`,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <RoletaContainer scale={scale}>
                    {/* @ts-ignore */}
                    <PinIconST
                        style={{
                            zIndex: 10,
                            transform: transformPin,
                        }}
                    >
                        <PinIcon style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: 'calc(50% + 15px)',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }} />
                    </PinIconST>
                    {/* @ts-ignore */}
                    <RoletaStyled
                        style={{ transform }}
                        ref={wheelRef}
                        {...bind()}
                    >
                        {items.map(([item, seg], index, arr) => (
                            <RoletaItem key={`Roleta-${index}`} style={{ transform: `rotate(${index * (360 / arr.length)}deg)` }} bgColor={!!(index % 2)} >
                                <IndcatorText viewBox='0 230px 0 40px' overflow='visible'>
                                    <path
                                        id="circlePath"
                                        d="M -1 60 C 40 30 179 30 229 60"
                                        fill='none'
                                    />
                                    <text textAnchor="middle">
                                        <textPath href="#circlePath" startOffset="50%" fill='#fff'>
                                            {seg}
                                        </textPath>
                                    </text>
                                </IndcatorText>
                                <div>
                                    <span>{item}</span>
                                </div>
                            </RoletaItem>
                        ))}

                        <CenterCircle size='350px' color={orangeGradient} />
                        <CenterCircle size='200px' color={useTheme().pallets.second.red} />
                    </RoletaStyled>
                </RoletaContainer >
            </div>
        </BodySession>
    );
};

export default RoletaInterativa;
