import React, { FC } from 'react';
import BodySession, { TitleBody } from '../../components/BodySession';

export interface CommisionTextProps { }

const CommisionText: FC<CommisionTextProps> = () => {
    return (
        <BodySession style={{
            textAlign: 'justify',
            alignItems: 'start',
        }}>
            <TitleBody>Modelo de Comissão</TitleBody>
            <p>O programa de afiliados será baseado exclusivamente em Revenue Share (Participação nos Lucros), começando com 24% e podendo chegar até 80%, conforme o desempenho do afiliado. Quanto mais lucros o afiliado gerar para a plataforma, maior será sua porcentagem de participação nos lucros.</p>
            <p>Além disso, o tamanho do influenciador (número de seguidores, engajamento e alcance) também será levado em consideração para calcular o crescimento da comissão.</p>
            <span>- 24% a 40%: Faixa inicial para novos afiliados ou influenciadores de pequeno porte.</span>
            <span>- 41% a 60%: Para afiliados que trazem maior número de jogadores e influenciadores com base média.</span>
            <span>- 61% a 80%: Para afiliados com grande número de indicações e influenciadores de grande alcance, que geram maior volume de receita para o cassino.</span>
        </BodySession>
    );
};

export default CommisionText;