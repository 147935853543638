import React from "react";
import Hero from "./Hero";
// import OurService from "./UorService";
// import HomeQuotes from "./Quote";
// import FAQ from "./FAQ";
import RoletaInterativa from "./spin";
import CommisionText from "./commisonModel";
import NiveisERegalisText from "./NiveisRegalias";

const HomePage: React.FC = () => {
    return (
        <>
            <Hero />
            {/* <OurService /> */}
            <CommisionText />
            <NiveisERegalisText />
            <RoletaInterativa />
            {/* <HomeQuotes /> */}
            {/* <FAQ /> */}
            <div style={{ height: "60px", width: '100%' }} />
        </>
    );
};

export default HomePage;
